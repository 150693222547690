 
import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';
import colors from 'vuetify/lib/util/colors'

 
 
Vue.use(Vuetify);

export default new Vuetify(opts);



const opts={
  theme: {
      options: {
        customProperties: true,
      },
      themes: {
        light: {
           primary: '#ee44aa',          
          secondary: '#424242',
          accent: '#82B1FF',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FFC107',
          accent2: colors.red.accent2,
        },
        dark: {
          primary: '#2196F3',
          secondary: '#424242',
          accent: '#FF4081',
          error: '#FF5252',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        },
      },
      customProperties: true,
      iconfont: 'mdi'
  },
  lang: {
      locales: {
        es
      },
      current: 'es',
  },
}
 
 