import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import vuetify from './plugins/vuetify';

import VueMeta from 'vue-meta';


//import '@mdi/font/css/materialdesignicons.css'

Vue.config.productionTip = false

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

new Vue({
	vuetify,
  router,
  store,
  render: h => h(App)    
}).$mount('#app')



