import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import HomeEn from '../views/HomeEn.vue'
import '@mdi/font/css/materialdesignicons.css'
 

Vue.use(VueRouter)

const routes = [ 
  {
    path: '/',
    name: 'Home',
    component: Home
  },
   
  {
    path: '/en',
    name: 'HomeEn',
    component: HomeEn,
    mode:'abstract'
    
  },
  
   
 
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


 
export default router
