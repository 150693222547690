<template>
  <div id="app">
    <router-view></router-view>      
  </div>
</template>

<script> 
export default {
  name: 'App',
  components:{
     
  }
  
}
</script>

<style>
@font-face {
  font-family: "PTSans";
  src: local("PTSans"),
   url(./fonts/PTSans-Regular.ttf) format("truetype");
}
#app {
  font-family: 'PTSans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  

}
$maincolor: #36699C;
</style>
