<template>
	<v-container>
		<div class="toolbarheader" >

			<div>
				<img class="imageheader" src="@/assets/logo-tauli.png">
			</div>
			<div>
				<v-text-field           
				outlined
				clearable             
				type="text"
				placeholder="Buscador"
				append-icon="mdi-magnify"
				>

			</v-text-field>

		</div>
		<div class="date"> 17 DIC. 2020</div>

	</div>	
	<div class="titleBar">
		<div class="row">
			<v-col cols="12" md="9">
				<h1>ESTACIÓN ARA  - TRABAJO CLÍNICO URGÉNCIAS</h1>
			</v-col> 
			<v-col cols="6" md="1">
				<v-select label="Castellano" :items="langItems" outlined>
				</v-select>
			</v-col>
			<v-col class="boxName" cols="6" md="2">
				<div>
					<v-row>
						<v-col cols="4" md="5"  >
							<div class="headline">MAR LÓPEZ </div>
							<span class="grey--text">3581</span>
						</v-col>
						<v-col cols="1" md="1" >
							<v-icon  large>mdi-account</v-icon>
						</v-col>
					</v-row> 
				</div>
			</v-col>
		</div>
	</div>
	<div class="barMenu">
		<div class="container">
			<div class="flex flex-row barMenu-flex">
				<button class="btn-menu">Acceso a HC</button>
				<button class="btn-menu">Registro actividad</button>
				<button class="btn-menu">Últimas Anotaciones Clínicas</button>
				<button class="btn-menu">Actividad Paciente</button>
			</div>
		</div>
	</div>
	<v-row>
		<v-col class="content" cols="12" xs="12" sm="12" md="6">
			<h2>MOTIVO DE CONSULTA</h2>

			<v-row>
				<v-col>
					<v-text-field
					name="name"
					label="DISNEA"
					outlined
					id="id"
					>DISNEA</v-text-field>
					<div class="tree">


						<div class="treePatology">
							<ul class="list list-1 firstchild showList ">
								<li class="item-2 " id="item-2" data-orderlist="1">
									<a href="/es/proceso-clinico/detail?show=2" class="" > Disnea aguda  </a>

									<ul class="list list-2  showList">
										<li class="item-1024 " id="item-1024" data-orderlist="0">
											<a href="/es/proceso-clinico/detail?show=1024" class="" > Tromboembolismo pulmonar  </a>

										</li>
										<li class="item-96 " id="item-96" data-orderlist="1">
											<a href="/es/proceso-clinico/detail?show=96" class="" > Ocupación de la luz bronquial y/o alveolar  </a>

											<ul class="list list-96  showList">
												<li class="item-97 " id="item-97" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=97" class="" > Neumonía típica  </a>

												</li>
												<li class="item-98 " id="item-98" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=98" class="" > Neumonía atípica  </a>

												</li>
												<li class="item-99 " id="item-99" data-orderlist="2">
													<a href="/es/proceso-clinico/detail?show=99" class="" > Hemorragia pulmonar  </a>

												</li>
												<li class="item-100 " id="item-100" data-orderlist="3">
													<a href="/es/proceso-clinico/detail?show=100" class="" > Neumonía por aspiración  </a>

												</li>
												<li class="item-101 " id="item-101" data-orderlist="4">
													<a href="/es/proceso-clinico/detail?show=101" class="" > Broncoespasmo  </a>

													<ul class="list list-101  showList">
														<li class="item-1053 " id="item-1053" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=1053" class="" > Asma  </a>

														</li>
														<li class="item-1054 " id="item-1054" data-orderlist="1">
															<a href="/es/proceso-clinico/detail?show=1054" class="" > Enfermedad Pulmonar Obstructiva Crónica (EPOC)  </a>

														</li>
													</ul>

												</li>
												<li class="item-109 " id="item-109" data-orderlist="5">
													<a href="/es/proceso-clinico/detail?show=109" class="" > Edema pulmonar cardiogénico  </a>

												</li>
												<li class="item-1055 " id="item-1055" data-orderlist="6">
													<a href="/es/proceso-clinico/detail?show=1055" class="" > Síndrome de dificultad respiratoria del adulto (distrés respiratorio)  </a>

												</li>
												<li class="item-1280 " id="item-1280" data-orderlist="7">
													<a href="/es/proceso-clinico/detail?show=1280" class="" > COVID-19  </a>

												</li>
											</ul>

										</li>
										<li class="item-102 " id="item-102" data-orderlist="2">
											<a href="/es/proceso-clinico/detail?show=102" class="" > Ocupación del espacio pleural  </a>

											<ul class="list list-102  showList">
												<li class="item-103 " id="item-103" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=103" class="" > Derrame pleural  </a>

												</li>
												<li class="item-104 " id="item-104" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=104" class="" > Neumotórax espontáneo  </a>

												</li>
											</ul>

										</li>
										<li class="item-1091 " id="item-1091" data-orderlist="3">
											<a href="/es/proceso-clinico/detail?show=1091" class="" > Origen extrapulmonar  </a>

											<ul class="list list-1091  showList">
												<li class="item-111 " id="item-111" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=111" class="" > Acidosis metabólica  </a>

												</li>
												<li class="item-1056 " id="item-1056" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=1056" class="" > Ansiedad  </a>

												</li>
											</ul>

										</li>
									</ul>

								</li>
								<li class="item-112 " id="item-112" data-orderlist="1">
									<a href="/es/proceso-clinico/detail?show=112" class="" > Disnea crónica  </a>

									<ul class="list list-112  showList">
										<li class="item-113 " id="item-113" data-orderlist="3">
											<a href="/es/proceso-clinico/detail?show=113" class="" > Enfermedades obstructivas  </a>

											<ul class="list list-113  showList">
												<li class="item-954 " id="item-954" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=954" class="" > Enfermedad Pulmonar Obstructiva Crónica (EPOC)  </a>

												</li>
												<li class="item-116 " id="item-116" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=116" class="" > Asma  </a>

												</li>
											</ul>

										</li>
										<li class="item-117 " id="item-117" data-orderlist="3">
											<a href="/es/proceso-clinico/detail?show=117" class="" > Enfermedades restrictivas  </a>

											<ul class="list list-117  showList">
												<li class="item-118 " id="item-118" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=118" class="" > Enfermedad pulmonar intersticial  </a>

												</li>
												<li class="item-119 " id="item-119" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=119" class="" > Deformidad de pared torácica  </a>

												</li>
												<li class="item-120 " id="item-120" data-orderlist="2">
													<a href="/es/proceso-clinico/detail?show=120" class="" > Obesidad  </a>

												</li>
												<li class="item-122 " id="item-122" data-orderlist="4">
													<a href="/es/proceso-clinico/detail?show=122" class="" > Enfermedades de las neuronas motoras  </a>

													<ul class="list list-122  showList">
														<li class="item-123 " id="item-123" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=123" class="" > Enfermedad de Duchenne  </a>

														</li>
														<li class="item-124 " id="item-124" data-orderlist="1">
															<a href="/es/proceso-clinico/detail?show=124" class="" > Esclerosis lateral amiotrófica  </a>

														</li>
														<li class="item-125 " id="item-125" data-orderlist="2">
															<a href="/es/proceso-clinico/detail?show=125" class="" > Parálisis bulbar progresiva  </a>

														</li>
														<li class="item-126 " id="item-126" data-orderlist="3">
															<a href="/es/proceso-clinico/detail?show=126" class="" > Neuropatías hereditarias  </a>

															<ul class="list list-126  showList">
																<li class="item-1174 " id="item-1174" data-orderlist="0">
																	<a href="/es/proceso-clinico/detail?show=1174" class="" > Enfermedad de Charcot-Marie-Tooth  </a>

																</li>
																<li class="item-1175 " id="item-1175" data-orderlist="1">
																	<a href="/es/proceso-clinico/detail?show=1175" class="" > Neuropatía hereditaria sensitiva  </a>

																</li>
															</ul>

														</li>
														<li class="item-127 " id="item-127" data-orderlist="4">
															<a href="/es/proceso-clinico/detail?show=127" class="" > Síndrome de Guillain-Barré  </a>

														</li>
														<li class="item-128 " id="item-128" data-orderlist="5">
															<a href="/es/proceso-clinico/detail?show=128" class="" > Miastenia gravis  </a>

														</li>
													</ul>

												</li>
												<li class="item-121 " id="item-121" data-orderlist="5">
													<a href="/es/proceso-clinico/detail?show=121" class="" > Fibrosis pleural  </a>

												</li>
											</ul>

										</li>
										<li class="item-129 " id="item-129" data-orderlist="3">
											<a href="/es/proceso-clinico/detail?show=129" class="" > Origen extrapulmonar  </a>

											<ul class="list list-129  showList">
												<li class="item-135 " id="item-135" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=135" class="" > Insuficiencia cardiaca  </a>

												</li>
												<li class="item-137 " id="item-137" data-orderlist="1">
													<a href="/es/proceso-clinico/detail?show=137" class="" > Disfunciones valvulares  </a>

													<ul class="list list-137  showList">
														<li class="item-1059 " id="item-1059" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=1059" class="" > Estenosis válvula aórtica  </a>

														</li>
														<li class="item-1060 " id="item-1060" data-orderlist="1">
															<a href="/es/proceso-clinico/detail?show=1060" class="" > Insuficiencia aórtica  </a>

														</li>
														<li class="item-1061 " id="item-1061" data-orderlist="2">
															<a href="/es/proceso-clinico/detail?show=1061" class="" > Estenosis mitral  </a>

														</li>
														<li class="item-1062 " id="item-1062" data-orderlist="3">
															<a href="/es/proceso-clinico/detail?show=1062" class="" > Insuficiencia mitral  </a>

														</li>
													</ul>

												</li>
												<li class="item-138 " id="item-138" data-orderlist="2">
													<a href="/es/proceso-clinico/detail?show=138" class="" > Arritmias  </a>

													<ul class="list list-138  showList">
														<li class="item-1064 " id="item-1064" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=1064" class="" > Bradiarritmias  </a>

															<ul class="list list-1064  showList">
																<li class="item-1066 " id="item-1066" data-orderlist="0">
																	<a href="/es/proceso-clinico/detail?show=1066" class="" > Bradicardia sinusal  </a>

																</li>
																<li class="item-1067 " id="item-1067" data-orderlist="1">
																	<a href="/es/proceso-clinico/detail?show=1067" class="" > Bloqueo auriculo-ventricular de primer grado  </a>

																</li>
																<li class="item-1068 " id="item-1068" data-orderlist="2">
																	<a href="/es/proceso-clinico/detail?show=1068" class="" > Bloqueo auriculo-ventricular de segundo grado  </a>

																</li>
																<li class="item-1069 " id="item-1069" data-orderlist="3">
																	<a href="/es/proceso-clinico/detail?show=1069" class="" > Bloqueo auriculo-ventricular completo  </a>

																</li>
															</ul>

														</li>
														<li class="item-1065 " id="item-1065" data-orderlist="1">
															<a href="/es/proceso-clinico/detail?show=1065" class="" > Taquiarritmias  </a>

															<ul class="list list-1065  showList">
																<li class="item-1070 " id="item-1070" data-orderlist="0">
																	<a href="/es/proceso-clinico/detail?show=1070" class="" > Taquicardia sinusal  </a>

																</li>
																<li class="item-1071 " id="item-1071" data-orderlist="1">
																	<a href="/es/proceso-clinico/detail?show=1071" class="" > Flutter auricular  </a>

																</li>
																<li class="item-1072 " id="item-1072" data-orderlist="2">
																	<a href="/es/proceso-clinico/detail?show=1072" class="" > Fibrilación auricular  </a>

																</li>
																<li class="item-1073 " id="item-1073" data-orderlist="3">
																	<a href="/es/proceso-clinico/detail?show=1073" class="" > Taquicardia supraventricular paroxística  </a>

																</li>
																<li class="item-1074 " id="item-1074" data-orderlist="4">
																	<a href="/es/proceso-clinico/detail?show=1074" class="" > Taquicardia ventricular  </a>

																</li>
																<li class="item-1075 " id="item-1075" data-orderlist="5">
																	<a href="/es/proceso-clinico/detail?show=1075" class="" > Fibrilación ventricular  </a>

																</li>
															</ul>

														</li>
													</ul>

												</li>
												<li class="item-139 " id="item-139" data-orderlist="3">
													<a href="/es/proceso-clinico/detail?show=139" class="" > Anemia  </a>

												</li>
												<li class="item-140 " id="item-140" data-orderlist="4">
													<a href="/es/proceso-clinico/detail?show=140" class="" > Hipertiroidismo  </a>

												</li>
												<li class="item-141 " id="item-141" data-orderlist="5">
													<a href="/es/proceso-clinico/detail?show=141" class="" > Enfermedades de vías respiratorias altas  </a>

													<ul class="list list-141  showList">
														<li class="item-1176 " id="item-1176" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=1176" class="" > Laringitis y traqueítis aguda  </a>

														</li>
													</ul>

												</li>
												<li class="item-1063 " id="item-1063" data-orderlist="6">
													<a href="/es/proceso-clinico/detail?show=1063" class="" > Ansiedad  </a>

												</li>
												<li class="item-143 " id="item-143" data-orderlist="7">
													<a href="/es/proceso-clinico/detail?show=143" class="" > Depresión  </a>

												</li>
												<li class="item-145 " id="item-145" data-orderlist="8">
													<a href="/es/proceso-clinico/detail?show=145" class="" > Reflujo gastroesofágico  </a>

												</li>
												<li class="item-146 " id="item-146" data-orderlist="9">
													<a href="/es/proceso-clinico/detail?show=146" class="" > Ascitis  </a>

												</li>
												<li class="item-147 " id="item-147" data-orderlist="10">
													<a href="/es/proceso-clinico/detail?show=147" class="" > Masa abdominal  </a>

												</li>
											</ul>

										</li>
										<li class="item-148 " id="item-148" data-orderlist="3">
											<a href="/es/proceso-clinico/detail?show=148" class="" > Enfermedades vasculares pulmonares  </a>

											<ul class="list list-148  showList">
												<li class="item-149 " id="item-149" data-orderlist="0">
													<a href="/es/proceso-clinico/detail?show=149" class="" > Tromboembolismo pulmonar crónico  </a>

												</li>
												<li class="item-150 " id="item-150" data-orderlist="2">
													<a href="/es/proceso-clinico/detail?show=150" class="" > Hipertensión pulmonar  </a>

												</li>
												<li class="item-151 " id="item-151" data-orderlist="2">
													<a href="/es/proceso-clinico/detail?show=151" class="" > Malformación arterio-venosa pulmonar  </a>

												</li>
												<li class="item-152 " id="item-152" data-orderlist="3">
													<a href="/es/proceso-clinico/detail?show=152" class="" > Vasculitis con afectación pulmonar  </a>

													<ul class="list list-152  showList">
														<li class="item-1076 " id="item-1076" data-orderlist="0">
															<a href="/es/proceso-clinico/detail?show=1076" class="" > Enfermedad de Churg-Strauss  </a>

														</li>
														<li class="item-1077 " id="item-1077" data-orderlist="1">
															<a href="/es/proceso-clinico/detail?show=1077" class="" > Granulomatosis de Wegener  </a>

														</li>
														<li class="item-1078 " id="item-1078" data-orderlist="2">
															<a href="/es/proceso-clinico/detail?show=1078" class="" > Poliangeítis microscópica  </a>

														</li>
														<li class="item-1080 " id="item-1080" data-orderlist="3">
															<a href="/es/proceso-clinico/detail?show=1080" class="" > Arteritis de Takayasu  </a>

														</li>
														<li class="item-1082 " id="item-1082" data-orderlist="4">
															<a href="/es/proceso-clinico/detail?show=1082" class="" > Crioglobulinemia  </a>

														</li>
														<li class="item-1083 " id="item-1083" data-orderlist="5">
															<a href="/es/proceso-clinico/detail?show=1083" class="" > Poliarteritis nodosa  </a>

														</li>
														<li class="item-1084 " id="item-1084" data-orderlist="6">
															<a href="/es/proceso-clinico/detail?show=1084" class="" > Enfermedad de Behçet  </a>

														</li>
														<li class="item-1085 " id="item-1085" data-orderlist="7">
															<a href="/es/proceso-clinico/detail?show=1085" class="" > Arteritis de células gigantes y polimialgia reumática  </a>

														</li>
														<li class="item-1086 " id="item-1086" data-orderlist="8">
															<a href="/es/proceso-clinico/detail?show=1086" class="" > Vasculitis por IgA (Púrpura de Henoch-Schönlein)  </a>

														</li>
													</ul>

												</li>
											</ul>

										</li>
									</ul>

								</li>
							</ul>
						</div>



					</div>
					<div class="data-pc">


					</div>
				</v-col>
				<v-col class="info-pacient ">
					<template>
						<v-expansion-panels>
							<v-expansion-panel
							v-for="(item,i) in expansionDisnea"
							vue		:key="i"
							>
							<v-expansion-panel-header v-if="item.title!='ANTECEDENTES'">
								{{ item.title }}
							</v-expansion-panel-header>
							<v-expansion-panel-header hide-actions v-else   :class="{antecedentesActive:antecedentesShow}" @click="antecedentesShow = !antecedentesShow">{{ item.title }}</v-expansion-panel-header>

						</v-expansion-panel>
					</v-expansion-panels>
				</template>

			</v-col>
		</v-row>
	</v-col>
	<v-col cols="12"  xs="12" sm="12" md="6" v-if="antecedentesShow"  class="antecedentesDetail  ">
		<v-card class="cardPatient">
			<h3>Antecedentes</h3>
			<div class="cards" > 
				<v-card outlined class="mx-auto"
				>
				<v-list-item three-line>
					<v-list-item-content>											 
						<v-list-item-title class="headline mb-1">
							<h5>Antecedentes Patológicos</h5>
						</v-list-item-title>
						<div class="text--primary">
							<ul>
								<li>Alcoholismo</li>
								<li>Asma</li>
								<li>Inmunodepresión</li>
								<li>Bronquiectasias </li>
							</ul>
						</div>
					</v-list-item-content>

				</v-list-item>
			</v-card>
			<v-card outlined class="mx-auto"
			>
			<v-list-item three-line>
				<v-list-item-content>											 
					<v-list-item-title class="headline mb-1">
						<h5>Condición</h5>	
					</v-list-item-title>
					<div class="text--primary">
						<ul>
							<li>Mayores de 70 años</li>
							<li>Asma</li>
							<li>Inmunodepresión</li>
						</ul>
					</div>
				</v-list-item-content>

			</v-list-item>
		</v-card>
		<v-card outlined class="mx-auto"
		>
		<v-list-item three-line>
			<v-list-item-content>											 
				<v-list-item-title class="headline mb-1">
					<h5>Habitos Tóxicos</h5>		
				</v-list-item-title>
				<div class="text--primary">
					<ul>
						<li>Alcoholismo</li> 
						<li> Tabaquismo</li>
						<li> EPOC</li>
					</ul>
				</div>
			</v-list-item-content>

		</v-list-item>
	</v-card>


</div>
</v-card>


</v-col>
<v-col class="patientInfo" cols="12" xs="12" sm="12" md="6" v-if="!antecedentesShow">
	<v-card class="cardPatient">
		<div class="row">
			<v-col cols="12" sm="8">
				<div class="cardrow">
					<v-col>Nº PACIENTE:</v-col>
					<v-col> ABCD9919K</v-col>
				</div>
				<div class="cardrow">
					<h3>PETER MOUNTAIN</h3>
				</div>
				<div class="cardrow">
					<v-col>Población:</v-col>
					<v-col>SABADELL</v-col>
				</div>
				<div class="cardrow">
					<v-col>
						H.C.N:
					</v-col>
					<v-col>
						zzfl143-19
					</v-col>
				</div>
				<div class="cardrow">
					<v-col>ALERGIAS:</v-col>
					<v-col>Aspirina</v-col>
				</div>
				<div class="cardrow">
					<v-col>Último Diagnóstico:</v-col>
					<v-col>Traumatismo en brazo derecho</v-col>
				</div>
				<div class="cardrow">
					<v-col>Antecedentes:</v-col>
					<v-col>Tensión alta</v-col>
				</div>
				<div class="cardrow">
					<v-col>Doctor:</v-col>
					<v-col>Escamilla E.</v-col>
				</div>
			</v-col>
			<v-col cols="12" sm="4" class="motivo-inicial" >
				<div class=" ">
					Motivo Incial: MAREO
				</div>
				<div class=" ">
					Edad: 51
				</div>

				<div class="">
					<img src="@/assets/paciente.jpg" alt="" width="100%">
				</div>

			</v-col>
		</div>
	</v-card>
</v-col>
</v-row>

<!-------->
<v-row>	

	<v-card class="datadetailClinica" v-if="showDetailClinica">	
		<div class="closeDetailClinica" style="position:relative;float:right;cursor:pointer"   @click="showDetailClinica = !showDetailClinica"> <v-icon medium> mdi-close </v-icon></div>
		<div style="display:flex;flex-direction:row;align-items:center;justify-content:center">
			<h3>Síntomas</h3>
			<v-text-field class="col-md-2"
			placeholder="Buscador"						 
			></v-text-field>
		</div>
		<div class="boxDetailClinica">
			<div class="arrow"> <v-icon>mdi-menu-left</v-icon> </div>
			<div>
				<v-card outlined class="mx-auto"
				>
				<v-list-item three-line>
					<v-list-item-content>											 
						<v-list-item-title class="headline mb-1">
							<h5>FIEBRE</h5>		
						</v-list-item-title>
						<div class="text--primary">
							
							<v-chip>R50 - FIEBRE DE OTRO ORIGEN Y DE ORIGEN DESCONOCIDO</v-chip>
							<v-chip>R50.9 - Fiebre, no especificada</v-chip>
							<v-chip>R50.81 - Fiebre asociada a afecciones clasificadas bajo otr..</v-chip>
							

						</div>
						<div class="text--primary">
							<ul>
								<li>Subclasificación:  Neumonía Típica</li> 
								<li>Frecuencia: Muy frecuente</li>
								<li>Forma de Inicio: Brusca</li>						 
							</ul>
						</div> 
					</v-list-item-content>

				</v-list-item>
			</v-card>
		</div>
		<div>
			<v-card outlined class="mx-auto"
			>
			<v-list-item three-line>
				<v-list-item-content>											 
					<v-list-item-title class="headline mb-1">
						<h5>Tos</h5>		
					</v-list-item-title>
					<div class="text--primary">

						<v-chip> R05- Tos</v-chip>					 
						<v-chip> R04.2- Hemoptisis</v-chip>
						<v-chip> J41.0- Bronquitis Crónica Simple</v-chip>

					</div>
					<div class="text--primary">
						<ul>						 
							<li>Frecuencia: Muy frecuente</li>
							<li> Forma de Inicio: Brusca</li>
						</ul>
					</div>
				</v-list-item-content>

			</v-list-item>
		</v-card>
	</div>
	<div>
		<v-card outlined class="mx-auto"
		>
		<v-list-item three-line>
			<v-list-item-content>											 
				<v-list-item-title class="headline mb-1">
					<h5>Expectoración purulenta</h5>		
				</v-list-item-title>


				<div class="text--primary">
					<ul>
						<li>Sinónimos:

							Esputo Amarillo,
							Esputo Verde,
							Esputo oscuro
						</li>
					</ul>
				</div>
				<div class="text--primary">
					<ul>
						<li>Frecuencia: Muy frecuente</li>
						<li>Forma de Inicio: Brusca</li> </ul>
					</div>

				</v-list-item-content>

			</v-list-item>
		</v-card>
	</div>
	<div class="arrow">
		<v-icon>mdi-menu-right</v-icon>  
	</div>
</div>


<div style="display:flex;flex-direction:row;align-items:center;justify-content:center">
	<h3>SIGNOS</h3>
	<v-text-field class="col-md-2"
	placeholder="Buscador"						 
	></v-text-field>
</div>
<div class="boxDetailClinica">
	<div class="arrow"> <v-icon>mdi-menu-left</v-icon> </div>
	<div>
		<v-card outlined class="mx-auto"
		>
		<v-list-item three-line>
			<v-list-item-content>											 
				<v-list-item-title class="headline mb-1">
					<h5>Crepitantes</h5>		
				</v-list-item-title>

				<div class="text--primary">
					<ul>	
						<li>Descripción: -						 </li>
						<li>Valores: -						 </li>			 
						<li>Frecuencia:   frecuente</li>
					</ul>
				</div> 
			</v-list-item-content>

		</v-list-item>
	</v-card>
</div>
<div>
	<v-card outlined class="mx-auto"
	>
	<v-list-item three-line>
		<v-list-item-content>											 
			<v-list-item-title class="headline mb-1">
				<h5>Taquicardia </h5>		
			</v-list-item-title>
			<div class="text--primary">

				<v-chip> R00.0 - Taquicardia, no especificada.</v-chip>					 


			</div>
			<div class="text--primary">
				<ul><li>Asociación con:  Fiebre,
				Sepsis grave con shock séptico</li>
			</ul>


		</div>
		<div class="text--primary">
			<ul>
				<li>Descripción: -						 </li>
				<li>Valores: -						 </li>
				<li>Frecuencia: Frecuente</li>
			</ul>
		</div>
	</v-list-item-content>

</v-list-item>
</v-card>
</div>
<div>
	<v-card outlined class="mx-auto"
	>
	<v-list-item three-line>
		<v-list-item-content>											 
			<v-list-item-title class="headline mb-1">
				<h5>Hipotensión arterial </h5>		
			</v-list-item-title>
			
			
			<div class="text--primary">
				<ul>
					<li>Sinónimos:

						Esputo Amarillo,
						Esputo Verde,
						Esputo oscuro
					</li>
				</ul>
			</div>
			<div class="text--primary">
				<ul>
					<li>Descripción: -						 </li>
					<li>Valores: -						 </li>
					<li>Frecuencia: Raro</li>	
					<li>Asociación con: Sepsis grave con shock séptico				</li>
				</ul>
			</div>

		</v-list-item-content>

	</v-list-item>
</v-card>
</div>
<div class="arrow">
	<v-icon>mdi-menu-right</v-icon>  
</div>
</div>




</v-card>
</v-row>
<div class="datadetail" v-if="!showDetailClinica">	
	<div class="columns-data" @click="showDetailClinica = !showDetailClinica">
		<h2>Clínica</h2>
		<h2>Síntomas</h2>
		<h2>Signos</h2>
	</div>

	<div  class="columns-data">
		<h2>Pruebas</h2>
		<h2>Analíticas</h2>
		<h2>Radiologia</h2>
	</div>

	<div class="columns-data">
		<h2>Prediagnóstico</h2>
		<h2>Terapéutico</h2>
	</div>
</div>
<v-row>

</v-row>

<v-footer>	
	<ul class="breadcrumbs">
		<li>
			<a class="completed">
				<span class="number">1</span>
				REC
			</a>
		</li>
		<li>
			<a class="completed">
				<span class="number">2</span>
				EVA
			</a>
		</li>
		<li>
			<a class="current">
				<span class="number">3</span>
				PRV
			</a>
		</li>
		<li>
			<a>
				<span class="number">4</span>
				TMAPV
			</a>
		</li>
		<li>
			<a>
				<span class="number">5</span>
				PRU
			</a>
		</li>
		<li>
			<a>
				<span class="number">5</span>
				RES
			</a>
		</li>
	</ul>


	<div class="footertop">
		<ul class="nav MenuPeu">
			<li class="item-517">
				<a accesskey="0" href="">Accesibilidad</a>
			</li>
			<li class="item-519">
				<a accesskey="7" href="">Aviso legal</a>
			</li>
			<li class="item-521">
				<a accesskey="" href="">Política de privacidad</a>
			</li>
			<li class="item-520">
				<a accesskey="" href=" ">Mapa</a>
			</li>
		</ul>
	</div>	
	<div class="subfooter logos">
		<img class="imgLogo" src="@/assets/logo-generalitat.png">
		<img class="imgLogo" src="@/assets/FEDER7.png" alt="">
	</div>
</v-footer>

</v-container>
</template>
<style lang="scss">
:root{
--maincolorbreadcrumb: 225;
--percentbr: 85%;
}
.boxDetailClinica{
	.text--primary {


	.v-chip{
	height:auto!important;
	margin: 8px!important;
	
}
}
}

.antecedentesDetail{
	border: 1px solid lightgray;
	border-radius:20px;
	margin-bottom:20px;
}
.antecedentesActive{
	background-color:  #36699C;
	color:white!important;
	
}

footer{
	margin-top:20px;
}

.footertop{
	width: 100%;
	margin-bottom:30px;
	.MenuPeu{
	list-style:none;
	justify-content:space-around;
	display: flex;
	flex-direction:row;
	width: 40%;margin:auto;
	@media screen and (max-width:768px){
	flex-direction:column;
}
}
}
.subfooter{
	margin-top:20px;
	width: 50%;
	margin:auto;
	&.logos{
	width: 40%;
	margin: auto;
	text-align: center;
	@media screen and (max-width:768px){
	width: 80%;
	flex-direction:column;
}
}
.imgLogo{
	height:40px;
	width: auto;
	margin-right:20px;

}
}
.cards{
	display:grid; grid-template-columns:1fr 1fr; grid-gap: 20px;
	@media screen and(max-width: 768px){
	grid-template-columns:1fr;
}

}
.cardrow{
	display: grid;
	grid-template-columns: 1fr 1fr;
}
.cardPatient{
	padding:20px;
}
.flex{display:flex;}
	.flex-row{flex-direction: row;}
	.titleBar{
	
	color: #36699C;
	padding:10px;
	@media screen and (max-width:768px){
	h1{
	font-size:1.5rem;
}
}
}
.barMenu-flex {
	flex-wrap: wrap!important;


}
.barMenu{
	//background-color:#333;
	//color:white;
	display: flex;
	flex-direction:row;
	align-items: center;
	justify-content: space-around;
	button{
	border:1px solid #36699C;
	border-radius: 30px;
	padding:10px;
	box-shadow: 2px 2px 1px  #36699C;
}
}
.btn-menu{
	text-transform: uppercase;
	font-size:16px;
	margin-right:20px;
	margin-top:10px;
	@media screen and (max-width:768px){
	font-size:12px;

}

}

.toolbarheader{	 
	background-image: linear-gradient(left, #ffffff 1%, #99ccff 99%);	 
	height: 100px;
	display:grid;
	grid-template-columns: 200px 1fr 200px;
	grid-gap:40px;
	@media screen and (max-width:768px){
	grid-template-columns:3fr 2fr 1fr;
}
> div{
display: flex;
flex-direction:column;
justify-content:center;

}
.boxName{
	border:  1px solid lightgray;
	width: auto;
}

.v-input__slot{
	margin-top:20px;
}
}
.autocompleteDiv{
	width: 50%;
	text-align:center;
	margin:auto;
}
.v-toolbar__content{
	height:100px!important;
}
.imageheader{
	height:auto;
	width: 100%;
	margin:10px;
}

.tree{
	margin: 0 5px;
	display: block;
	a{text-decoration:none; color:inherit;}
	ul {
	margin-left: 2em;
	position: relative;
}
> li {
border-bottom: 1px solid lightgray;
padding: 10px 0;
}
li{
	position: relative
}
}

.treePatology{
	overflow-y:scroll;
	height:300px;
}

.date{
	font-size:20px;
}




.columns-data{
	border:1px solid lightgray;
	padding:10px 20px;
}
.datadetail{
	display: grid;
	grid-template-columns: 3fr 3fr 6fr;
	grid-gap:20px;
	@media screen and (max-width:768px){
	grid-template-columns:1fr;
}
}
.datadetailClinica{
	padding:20px 10%;
	border:1px solid lightgray;

}
.boxDetailClinica{
	display:grid; grid-template-columns: 50px repeat(3,1fr) 50px; grid-gap:10px;
	@media screen and (max-width:768px	){
	grid-template-columns:1fr;
}

}
.arrow{
	display: flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	font-size:40px;
}



/*********************************/
/*********************************/

$incomplete: #000;
$completed: #36699C;
$current: lighten(#FF0000, 15%);
$pulse-length: 1s;

ul.breadcrumbs {
	margin:   50px auto;
	list-style: none;

	&:after {
	clear: both;
	content: "";
	display: table; 
}

& li {
	display: block;
	float: left;
	margin: 0px;
	padding: 0px;

	& a {
	cursor: pointer;
	display: inline-block;
	background-color: $incomplete;
	color: white;
	line-height: 40px;
	padding: 0 35px 0 50px;
	position: relative;
	box-shadow: 0px 0px 10px $incomplete;

	&:after, &:before {
	display: block;
	position: absolute;
	content: "";
	background-color: $incomplete;
}

&:after {
	right: -15px;
	height: 28.5px;
	width: 28.5px;
	top: 4px;
	z-index: 5;
	transform: rotate(45deg);
	border-right: 3px white solid;
	border-top: 3px white solid;
	border-radius: 3px;
}

&:before {
	right: 3px;
	width: 30px;
	height: 40px;
	z-index: 6;
}

& span.number {
	border-radius: 50%;
	display: inline-block;
	line-height: 20px;
	background-color: white;
	font-family: "athelas", serif;
	font-weight: bold;
	color: black;
	width: 20px;
	text-align: center;
	margin-right: 5px;
}

&.completed {
	background-color: $completed;
	color: black;
	box-shadow: 0px 0px 10px $completed;

	&:after, &:before {
	background-color: $completed;
}
}

&.current {
	background-color: $current;
	animation-duration: $pulse-length;
	animation-name: pulse, glowPulse;
	animation-direction: alternate;
	animation-iteration-count: infinite;
	box-shadow: 0px 0px 10px $current;

	&:after, &:before {
	background-color: $current;
	animation-duration: $pulse-length;
	animation-name: pulse;
	animation-direction: alternate;
	animation-iteration-count: infinite;
}

&:after {
	animation-name: pulse;
}
}

}

&:first-child a {
	padding: 0 35px;
	border-radius: auto auto auto 3px;
}

&:last-child  a{
	border-radius: auto 3px auto;

	&:after {
	display: none;
}
}

}
}

@keyframes pulse {
from {
background-color:$incomplete;
}

to {
background-color:$current;
}
}

@keyframes glowPulse {
from {
box-shadow: 0px 0px 10px $incomplete;
}

to {
box-shadow: 0px 0px 10px $current;
}
}


/*********************************/
/*********************************/

</style>
<script>

export default{
	name:'Home',	

	data:()=>({
		langItems:['English','French'],
		expansionDisnea:[
		{
			title: 'INFORMACIÓN GENERAL'
		},
		{
			title: 'DATOS EPIDEMIOLÓGICOS'
		},
		{
			title: 'ANTECEDENTES'

		},
		{
			title:'ALGORITMOS',

		},
		{title: 'ESCALAS | FUENTES'}
		], 
		antecedentesShow:false,
		showDetailClinica:false,
		absolute: true,
		opacity:0.5,

	}),
	watch: {
		antecedenteShow (val) {
			val && setTimeout(() => {
				this.antecedenteShow = false
			}, 15000)
		},
	},

}
</script>